import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import Receiving from 'wms/receiving/receiving';
import ReceivingTasks from 'wms/receiving/tasks';

export default function InboundReceiving(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div className="bg-background h-full">
          <Router>
            <Receiving path="/wms/receiving" />
            <ReceivingTasks path="/wms/receiving/tasks/:sessionId" />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
