import { useCompleteInbound, useInboundSession } from 'apiHooks/wmsHooks';
import React from 'react';
import { useBranchId } from 'utils/customHooks';
import SessionTasks from 'wms/molecules/SessionTasks';

export default function ReceivingTasks(props) {
  const { sessionId } = props;
  const branchId = useBranchId();
  const { data, isFetching } = useInboundSession({ branchId, sessionId });
  const [forceComplete] = useCompleteInbound({ branchId, sessionId });

  const session = data && data.length > 0 ? data[0] : undefined;

  return <SessionTasks {...{ session, forceComplete, isFetching }} />;
}
