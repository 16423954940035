import { useCreateInboundSession, useInboundSession } from 'apiHooks/wmsHooks';
import React, { useState } from 'react';
import { useBranchId } from 'utils/customHooks';
import { getDateRange } from 'utils/utils';
import ASNSession from 'wms/molecules/ASNSessions';
import WmsFilters from 'wms/WmsFilters';

const initialDates = getDateRange(new Date(), 7);

export default function Receiving() {
  const branchId = useBranchId();
  const [startDate, setStartDate] = useState(initialDates[0]);
  const [endDate, setEndDate] = useState(initialDates[1]);

  function onDateFilterChanged(dates) {
    const newDates = dates.length ? dates : initialDates;

    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  }

  const { data, isFetching } = useInboundSession({ branchId, startDate, endDate });
  const sessionList = data; //data?.filter((t) => t.state !== 'COMPLETED') || [];
  const [createSession] = useCreateInboundSession({ branchId });

  return (
    <ASNSession
      title={'Receiving Tasks List'}
      url={`/wms/receiving/tasks`}
      sessions={sessionList}
      isFetching={isFetching}
      createSession={createSession}
      filters={
        <WmsFilters
          onFiltersChange={onDateFilterChanged}
          onFilterCancelled={() => onDateFilterChanged([])}
        />
      }
    />
  );
}
